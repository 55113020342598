import React from 'react';

import Layout from '../components/Layout';
import { LiveMessage } from 'react-aria-live';
import Helmet from 'react-helmet';
import { css } from '@emotion/core';

const notFoundStyle = css`
  font-size: 1.5em;
`;

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <LiveMessage
          message="Navigated to not found page"
          aria-live="assertive"
        />
        <Helmet>
          <title>Vestiges of War | Page not found</title>
        </Helmet>
        <h1>Not Found</h1>
        <p css={notFoundStyle}>You navigated to a page that does not exist.</p>
      </Layout>
    );
  }
}

export default NotFoundPage;
